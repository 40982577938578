import React, { useState, useEffect } from 'react'
import { 
    Input, 
    Typography,
    Layout,
    Card,
    Col,
    Row,
    Button,
    Form,
    Modal
} from 'antd'
import { Auth } from '../../modules'
import { Notification } from '../../components'
import LOGO from '../../logo.png'
import { ArrowLeftOutlined } from '@ant-design/icons'
import Router from '../../router'

const { Title, Text } = Typography,
    { Content } = Layout

export default function LoginPage ({ setLogged, setCurrentPage }) {
    const [loginObj, setLoginObj] = useState({
        email: '',
        password: ''
    }),
    [termsModalOpened, setTermsModalOpened] = useState(false)

    const handleLogin = async () => {
        if(!loginObj.email.length || !loginObj.password.length) return Notification({ 
            description: 'Email e senha obrigatórios',
            type: 'warning',
            title: 'Login'
        })

        const data = await Auth.login({ email: loginObj.email, password: loginObj.password })

        if(data?.data?.token?.length) setLogged(true)
    }

    const handleOpenTermsModal = (stat) => {
        setTermsModalOpened(stat)
    }

    useEffect(() => {
        if(localStorage.getItem('@token')) setLogged(true)
    }, [])
    
    return (
        <Layout>
            <Content
                className="site-layout"
                style={{
                    background: 'radial-gradient(circle, rgba(174,196,238,1) 0%, rgba(154,154,154,1) 100%)'
                }}
            >
                <Row justify='center' align="middle" style={{
                    minHeight: '100vh'
                }}>
                    <Col>
                        {/* <ArrowLeftOutlined 
                            style={{ 
                                cursor: 'pointer', 
                                fontSize: '20px', 
                                padding: 10 
                            }} 
                            onClick={() => {
                                setCurrentPage(Router.landingPage({ setCurrentPage, setLogged }))
                            }}
                        /> */}
                        <img style={{ margin: '0 auto', width: '20rem', display: 'block' }} src={LOGO} alt='logo imagem' />
                        <Card 
                        bordered={false}
                        style={{ 
                            width: 400, 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            background: 'transparent',
                            boxShadow: 'none'
                        }}>
                            <Title level={3}>Entrar</Title>
                            <Form style={{ width: 300 }}>
                                <Form.Item>
                                    <Input type={'email'} onChange={(e) => {
                                        let obj = loginObj
                                        obj.email = e.target.value
                                        setLoginObj({ ...obj })
                                    }} size={'large'} placeholder="Email" />
                                </Form.Item>
                                <Form.Item>
                                    <Input.Password 
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleLogin()
                                            }
                                        }}
                                        onChange={(e) => {
                                            let obj = loginObj
                                            obj.password = e.target.value
                                            setLoginObj({ ...obj })
                                        }} 
                                        size={'large'} 
                                        placeholder="Senha" 
                                    />
                                </Form.Item>
                            </Form>
                            <Modal 
                                title={'TERMOS DE USO SimpleTOOL'}
                                centered
                                open={termsModalOpened}
                                onCancel={() => handleOpenTermsModal(false)}
                                width={1000}
                                onOk={() => setCurrentPage(Router.setupPage({ setCurrentPage, setLogged }))}
                                okText="Aceito os termos de uso e de Privacidade"
                                cancelText="Cancelar"
                                cancelButtonProps={{ type: 'text' }}
                                bodyStyle={{ overflowY: 'scroll', maxHeight: 500 }}
                            >
                                <Text>
                                    {`
                                        Bem-vindo ao SimpleTOOL, nosso sistema de ferramentas de desenvolvimento de software em nuvem. Antes de utilizar nossos serviços, solicitamos que você leia atentamente estes Termos e Condições de Uso. Ao acessar e utilizar nosso sistema, você concorda em cumprir estes termos e condições, bem como todas as leis e regulamentos aplicáveis. Se você não concorda com estes termos, solicitamos que não utilize nossos serviços.
                                    `}
                                    <h5 style={{ marginTop: 10 }}>
                                        {'Serviços do SimpleTOOL'}
                                    </h5>
                                    <b>1.1</b> {'O SimpleTOOL é um sistema que possui diversas ferramentas de desenvolvimento de software em nuvem que rodam diretamente em seu próprio servidor. Nossa plataforma online oferece uma interface intuitiva para a criação e gerenciamento de seu software.'}<br></br>
                                    <b>1.2</b> {'Você é o único responsável pelo conteúdo que cria e pela utilização adequada do SimpleTOOL.'}<br></br>
                                    <h5 style={{ marginTop: 10 }}>
                                        {' Segurança dos Dados'}
                                    </h5>
                                    <b>2.1</b> {'Implementamos medidas para proteger os dados transmitidos e armazenados em nosso sistema, como: criptografia, token de acesso entre outros.'}<br></br>
                                    <b>2.2</b> {'Não temos acesso a informações delicadas do seu servidor ou aos dados trafegados. Todo o conteúdo e dados são armazenados em seu próprio servidor.'}<br></br>
                                    <b>2.3</b> {'Recomendamos que você mantenha suas senhas e chaves de acesso em local seguro, além de manter seu software e sistemas atualizados para garantir a segurança das conexões e dos dados.'}<br></br>
                                    <h5 style={{ marginTop: 10 }}>
                                        {'Pagamentos'}
                                    </h5>
                                    <b>3.1</b> {'Oferecemos planos de assinatura mensal e anual para o uso do SimpleTOOL.'}<br></br>
                                    <b>3.2</b> {'Em caso de inadimplência com os pagamentos por um período de 5 dias, reservamo-nos o direito de bloquear o acesso ao SimpleTOOL. Isso pode resultar na inoperância dos serviços prestados até que a situação seja regularizada.'}<br></br>
                                    <h5 style={{ marginTop: 10 }}>
                                        {'Propriedade Intelectual'}
                                    </h5>
                                    <b>4.1</b> {'Todo o conteúdo disponibilizado no SimpleTOOL, incluindo textos, gráficos, logotipos, imagens e software, é de propriedade exclusiva de nossa empresa ou de nossos licenciadores, e está protegido por leis de direitos autorais e outras leis de propriedade intelectual.'}<br></br>
                                    <b>4.2</b> {'Não há permissão para armazenar, reproduzir, distribuir, modificar, exibir publicamente, criar trabalhos derivados ou explorar comercialmente qualquer parte do conteúdo do SimpleTOOL sem nossa autorização prévia por escrito.'}<br></br>
                                    <h5 style={{ marginTop: 10 }}>
                                        {'Limitação de Responsabilidade'}
                                    </h5>
                                    <b>5.1</b> {'O SimpleTOOL é fornecido "no estado em que se encontra", e não oferecemos garantias expressas ou implícitas sobre sua adequação a um propósito específico, desempenho, segurança ou confiabilidade.'}<br></br>
                                    <b>5.2</b> {'Não nos responsabilizamos por quaisquer danos diretos, indiretos, incidentais, consequenciais ou punitivos decorrentes do uso ou da impossibilidade de uso do SimpleTOOL.'}<br></br>
                                    <h5 style={{ marginTop: 10 }}>
                                        {'Alterações nos Termos e Condições'}
                                    </h5>
                                    <b>6.1</b> {'Reservamo-nos o direito de modificar estes Termos e Condições de Uso a qualquer momento. Quaisquer alterações serão publicadas em nosso sistema e entrarão em vigor imediatamente após a publicação.'}<br></br>
                                    <b>6.2</b> {'É sua responsabilidade revisar periodicamente estes termos e condições para estar ciente de quaisquer alterações. O uso contínuo do SimpleTOOL após a publicação de alterações implica na sua aceitação dessas modificações.'}<br></br> 
                                    <h5 style={{ marginTop: 10 }}>
                                        {'Encerramento de Conta'}
                                    </h5>
                                    <b>7.1</b> {'Você pode encerrar sua conta conosco a qualquer momento, porém não há reembolso de quaisquer valores pagos e seu acesso e disponibilidade do serviço continuarão sendo fornecidos até o último dia pago.'}<br></br>
                                    <b>7.2</b> {'Reservamo-nos o direito de encerrar sua conta ou restringir seu acesso ao SimpleTOOL caso venha violar estes termos e condições ou se houver atividades suspeitas ou fraudulentas relacionadas à sua conta.'}<br></br> 
                                    <h5 style={{ marginTop: 10 }}>
                                        {'Disposições Gerais'}
                                    </h5>
                                    <b>8.1</b> {'Estes termos e condições constituem o acordo completo entre você e nossa empresa em relação ao uso do SimpleTOOL, substituindo quaisquer acordos anteriores.'}<br></br>
                                    <b>8.2</b> {'A falha de nossa parte em exercer ou fazer cumprir qualquer direito ou disposição destes termos não constitui renúncia a esse direito ou disposição.'}<br></br> 
                                    <b>8.3</b> {'Caso alguma disposição destes termos e condições seja considerada inválida ou inexequível, as demais disposições permanecerão em pleno vigor e efeito.'}<br></br> 
                                </Text>
                                <a href='https://simpletool.com.br/politica' style={{ marginTop: 20 }} target={'_blank'}>Acesse a nossa Politica de Privacidade (LGPD)</a>
                            </Modal>
                            <h4 style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleOpenTermsModal(true)}>
                                {'Possui o número de serial? clique aqui.'}
                            </h4>
                            {
                                (window.location.href.match(/sandbox/) || window.location.href.match(/localhost/)) && (
                                    <a href='https://api.whatsapp.com/send?phone=+5511955008090&text=Solicitar+teste' target='_BLANK'>
                                        <h4 style={{ cursor: 'pointer', color: 'blue' }}>
                                            {'Não tem cadastro? Solicite um teste aqui.'}
                                        </h4>
                                    </a>
                                )
                            }
                            <Button style={{ 
                                float: 'right',
                                marginTop: 15 
                            }} 
                            onClick={handleLogin}
                            shape="round" size={'large'}>
                                Entrar
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
    
}