
class APIDocGenerator {

    #data
    #html

    constructor (data) {
        this.#data = data
        this.#html = ''
    }

    mountHeader (headerData) {
        let html = `
            <div class="header">
                <h1>
                    Micro Serviço - ${headerData?.name || 'Nome não definido'}
                </h1>
                ${headerData?.description?.length > 0 && `
                    <h4>
                        ${headerData?.description}
                    </h4>
                `}
            </div>
        `

        html += `
                ${headerData.hasAuthentication && `<div class="authorization">
                    <h4>
                        Authorization
                    </h4>
                    <p>
                        Essa API necessita de um <b>token Bearer</b> enviado no <b>Header</b> da requisição para concluir a solicitação.
                    </p>
                    <div class="table-wrapper">
                        <table class="pdf-table">
                            <thead>
                                <tr>
                                    <th>
                                        Header
                                    </th>
                                    <th>
                                        Value
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Content-type
                                    </td>
                                    <td>
                                        application/json
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Authorization
                                    </td>
                                    <td>
                                        Bearer \${token}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ` || ''}
            ${headerData?.hasCache && `
                <div class="cache">
                    <h4>
                        Cache de requisição
                    </h4>
                    <p>
                        Essa API utiliza CACHE de requisição para <b>ENDPOINTS</b> do verbo http <b>POST</b> e o cache expira a cada 15 minutos, sendo gerado um novo.
                    </p>
                </div>
            ` || ''}
            ${headerData?.hasACL && `
                <div class="cache">
                    <h4>
                        ACL (Controle de acesso)
                    </h4>
                    <p>
                        Essa API utiliza ACL para o controle de acesso, sendo <b>OBRIGATÓRIO</b> na sessão. São eles:
                    </p>
                    <div class="table-wrapper">
                        <table id="acl-table" class="pdf-table">
                            <thead>
                                <tr>
                                    <th>
                                        Value
                                    </th>
                                    <th>
                                        Is Required
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {{aclRolesData}}
                            </tbody>
                        </table>
                    </div>
                </div>
            ` || ''}
        `

        if(headerData.hasACL) {
            let tmpHtml = ''

            headerData.acl?.roles?.forEach(aclRole => {
                tmpHtml += `
                    <tr>
                        <td>
                            ${aclRole}
                        </td>
                        <td>
                            Sim
                        </td>
                    </tr>
                `
            })

            html.replace('{{aclRolesData}}', tmpHtml)
        }

        return html
    }

    async generateDoc () {
        const html = `
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <style>
                    body {
                        margin: 15px;
                        text-align: center;
                    }

                    .authorization, .cache {
                        margin-top: 30px;
                        margin-bottom: 30px;
                    }

                    .table-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                    }

                    .pdf-table {
                        width: 100%;
                        border-collapse: collapse;
                    }

                    .pdf-table th, td {
                        border: 1px solid #000;
                        font-weight: 700;
                    }

                    .pdf-table thead th {
                        background-color: #d6dcea;
                    }
                </style>
            </head>
            <body>
                {{bodyData}}                  
        </html>
        `
        
        const acl = this.#data?.acl || {}
        const app = this.#data?.app || {
            "name": "users-teste-spr",
            "app_description": "trás todos os usuários da tabela 3",
            "endpoint_name": "users-spr",
            "endpoint_name2": "",
            "client_id": "638d7acb34c487fec18b7925",
            "connection_id": "6565495d5b2cb4b200e1dcd3",
            "group_id": null,
            "cache_query": false,
            "debug_on_response": true,
            "acl_id": null,
            "has_authentication": false,
            "table": "users",
            "status": 1,
            "query_string_sql": 1,
            "response": {
                "list": {
                    "schema": []
                },
                "show": {
                    "schema": []
                },
                "create": {
                    "schema": []
                },
                "update": {
                    "schema": []
                },
                "delete": {
                    "schema": []
                }
            },
            "request": {
                "list": {
                    "status": 1,
                    "order_by": null,
                    "order_by_type": null,
                    "raw_query": "",
                    "dev_mode": {
                        "dev": {
                            "code": ""
                        },
                        "sdb": {
                            "code": ""
                        },
                        "hml": {
                            "code": ""
                        },
                        "prd": {
                            "code": ""
                        }
                    }
                },
                "show": {
                    "status": 1,
                    "raw_query": "SELECT * FROM {table} WHERE id = {id}",
                    "dev_mode": {
                        "dev": {
                            "code": ""
                        },
                        "sdb": {
                            "code": ""
                        },
                        "hml": {
                            "code": ""
                        },
                        "prd": {
                            "code": ""
                        }
                    }
                },
                "create": {
                    "status": 1,
                    "schema": "",
                    "dev_mode": {
                        "dev": {
                            "code": ""
                        },
                        "sdb": {
                            "code": ""
                        },
                        "hml": {
                            "code": ""
                        },
                        "prd": {
                            "code": ""
                        }
                    }
                },
                "update": {
                    "status": 1,
                    "where": "id = {id}",
                    "schema": "",
                    "dev_mode": {
                        "dev": {
                            "code": ""
                        },
                        "sdb": {
                            "code": ""
                        },
                        "hml": {
                            "code": ""
                        },
                        "prd": {
                            "code": ""
                        }
                    }
                },
                "delete": {
                    "status": 1,
                    "raw_query": "",
                    "dev_mode": {
                        "dev": {
                            "code": ""
                        },
                        "sdb": {
                            "code": ""
                        },
                        "hml": {
                            "code": ""
                        },
                        "prd": {
                            "code": ""
                        }
                    }
                }
            },
            "created_at": "2023-11-27T23:01:09.708-03:00",
            "updated_at": "2023-11-29T16:22:37.318-03:00"
        }

        if(!Object.keys(app)?.length) return this

        let bodyData = ''

        const headerHTML = this.mountHeader({
            appName: app.name,
            description: app.description,
            endpoint_name: app.endpoint_name,
            endpoint_name2: app.endpoint_name2,
            hasCache: true || app.cache_query,
            hasACL: true || app.acl_id?.length > 0,
            hasAuthentication: true || app.hasAuthentication?.length > 0,
            acl
        })

        bodyData += headerHTML

        this.#html = html.replace('{{bodyData}}', bodyData)

        return this
    }

    async downloadFile () {
        // const blob = new Blob([pdfFile], { type: 'application/pdf' });

        // if(window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveBlob(blob, 'test.pdf');
        // } else {
        //     const elem = window.document.createElement('a');
        //     elem.href = window.URL.createObjectURL(blob);
        //     elem.download = 'test.pdf';        
        //     document.body.appendChild(elem);
        //     elem.click();        
        //     document.body.removeChild(elem);
        // }
    }

}

export default APIDocGenerator