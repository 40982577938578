import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form,
    Tag,
    Tabs,
    Switch,
    Tooltip,
    Col,
    Row,
    Select
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons'
import { Notification } from "../.."
import { 
    Apps
} from "../../../modules"
import TabList from "./tab-list"
import TabGet from "./tab-get"
import TabPost from "./tab-post"
import TabPut from "./tab-put"
import TabDelete from "./tab-delete"
import { useSWRConfig } from "swr"

const { Item } = Form

export default function AppModal ({ title = '', isMobile = false, open = false, handleOpen = () => {}, editApp = {}, mutating = () => {} }) {
    const { cache } = useSWRConfig()

    const [app, setApp] = useState(null),
    [loading, setLoading] = useState(false),
    [tabs, setTabs] = useState([]),
    [modalIsLoading, setModalIsLoading] = useState(true),
    isEditModal = editApp?._id ? true : false

    const connectionsData = cache.get('@connections')
    const groupsData = cache.get('@groups')
    const aclData = cache.get('@acls')
    const workersData = cache.get('@workers')
    const corsData = cache.get('@cors')

    const handleSave = async () => {
        setLoading(true)

        let rawQueriesError = false,
            rawQueriesErrorsDesc = []

        if(app.request.list.raw_query?.length > 0) {
            app.request.list?.raw_query?.forEach(reqList => {
                if(!reqList.slice(0,6).toLowerCase().match(/select/)) {
                    rawQueriesError = true
                    rawQueriesErrorsDesc.push('A RAW QUERY de "LIST" deve começar com "SELECT"')
                }
            })
        }

        if(app.request.show.raw_query?.length > 0) {
            app.request.show?.raw_query?.forEach(reqShow => {
                if(!reqShow.slice(0,6).toLowerCase().match(/select/)) {
                    rawQueriesError = true
                    rawQueriesErrorsDesc.push('A RAW QUERY de "GET" deve começar com "SELECT"')
                }
            })
        }

        if(app.request.delete.raw_query?.length > 0) {
            const deleteRawQuery = app.request.delete.raw_query.slice(0,6).toLowerCase()
            if(!(deleteRawQuery.match(/delete/) || deleteRawQuery.match(/update/))) {
                rawQueriesError = true
                rawQueriesErrorsDesc.push('A RAW QUERY de "DELETE" deve começar com "DELETE" ou "UPDATE"')
            }
        }

        if(rawQueriesError) {
            setLoading(false)

            return Notification({ 
                description: `${rawQueriesErrorsDesc.join('; ')}`,
                title: 'Raw Queries',
                type: 'warning'
            })
        }

        if(!app?.name?.length) {
            setLoading(false)

            return Notification({ 
                description: `O campo Nome é obrigatório`,
                title: 'Salvar API',
                type: 'warning'
            })
        }

        // list dev mode
        if(app.request.list.dev_mode.dev.code.length > 0) app.request.list.dev_mode.dev.code = app.request.list.dev_mode.dev.code
        if(app.request.list.dev_mode.sdb.code.length > 0) app.request.list.dev_mode.sdb.code = app.request.list.dev_mode.sdb.code
        if(app.request.list.dev_mode.hml.code.length > 0) app.request.list.dev_mode.hml.code = app.request.list.dev_mode.hml.code
        if(app.request.list.dev_mode.prd.code.length > 0) app.request.list.dev_mode.prd.code = app.request.list.dev_mode.prd.code

        // show dev mode
        if(app.request.show.dev_mode.dev.code.length > 0) app.request.show.dev_mode.dev.code = app.request.show.dev_mode.dev.code
        if(app.request.show.dev_mode.sdb.code.length > 0) app.request.show.dev_mode.sdb.code = app.request.show.dev_mode.sdb.code
        if(app.request.show.dev_mode.hml.code.length > 0) app.request.show.dev_mode.hml.code = app.request.show.dev_mode.hml.code
        if(app.request.show.dev_mode.prd.code.length > 0) app.request.show.dev_mode.prd.code = app.request.show.dev_mode.prd.code

        // create dev mode
        if(app.request.create.dev_mode.dev.code.length > 0) app.request.create.dev_mode.dev.code = app.request.create.dev_mode.dev.code
        if(app.request.create.dev_mode.sdb.code.length > 0) app.request.create.dev_mode.sdb.code = app.request.create.dev_mode.sdb.code
        if(app.request.create.dev_mode.hml.code.length > 0) app.request.create.dev_mode.hml.code = app.request.create.dev_mode.hml.code
        if(app.request.create.dev_mode.prd.code.length > 0) app.request.create.dev_mode.prd.code = app.request.create.dev_mode.prd.code

        // update dev mode
        if(app.request.update.dev_mode.dev.code.length > 0) app.request.update.dev_mode.dev.code = app.request.update.dev_mode.dev.code
        if(app.request.update.dev_mode.sdb.code.length > 0) app.request.update.dev_mode.sdb.code = app.request.update.dev_mode.sdb.code
        if(app.request.update.dev_mode.hml.code.length > 0) app.request.update.dev_mode.hml.code = app.request.update.dev_mode.hml.code
        if(app.request.update.dev_mode.prd.code.length > 0) app.request.update.dev_mode.prd.code = app.request.update.dev_mode.prd.code

        // delete dev mode
        if(app.request.delete.dev_mode.dev.code.length > 0) app.request.delete.dev_mode.dev.code = app.request.delete.dev_mode.dev.code
        if(app.request.delete.dev_mode.sdb.code.length > 0) app.request.delete.dev_mode.sdb.code = app.request.delete.dev_mode.sdb.code
        if(app.request.delete.dev_mode.hml.code.length > 0) app.request.delete.dev_mode.hml.code = app.request.delete.dev_mode.hml.code
        if(app.request.delete.dev_mode.prd.code.length > 0) app.request.delete.dev_mode.prd.code = app.request.delete.dev_mode.prd.code
        // setLoading(false)
        // return console.log(app.request) 

        if(!app.acl_id?.length) {
            app.acl_id = null
        }
        
        setApp({ ...app })

        let hasError = false,
            response = null

        if(isEditModal) {
            response = await Apps.updateClientApp({ app, id: editApp?._id })

            if(response?.data?.has_prs) {
                setLoading(false)
                handleOpen(false)

                return handleNotificationPR()
            }

            hasError = response == true || false
        } else {
            response = await Apps.createClientApp({ app })

            hasError = response == true || false
        }

        setLoading(false)

        if(hasError) return 

        handleOpen(false)
        handleNotificationSuccess()
        await mutating()
    }

    const handleNotificationPR = () => {
        Notification({ 
            description: `Não foi possível atualizar o API, há PRs em aberto, verifique e tente novamente`,
            title: 'API',
            type: 'error'
        })
    }

    const handleNotificationSuccess = () => {
        const modalType = isEditModal ? 'editada' : 'criada'
        
        Notification({ 
            description: `API ${modalType} com sucesso`,
            title: 'API'
        })
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(key.match(/dev_mode/)?.length > 0) {
            const keySplit = key.split('.')
            app.request[keySplit[1]].dev_mode[keySplit[3]].code = `${e}`
        } else if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = app[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                const r = new RegExp(`array.string.\*`, 'g')

                if(type.match(r)?.length) {
                    const index = parseInt(type.split('.')[2])
                    let hasIndex = typeof tmpValue[splitedKeys[splitedKeys.length - 1]][index] == 'string'

                    if(!hasIndex) tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                    else {
                        let newValue = hasIndex ? tmpValue[splitedKeys[splitedKeys.length - 1]][index] : ''
                        
                        newValue = !value ? '': value
    
                        tmpValue[splitedKeys[splitedKeys.length - 1]][index] = newValue
                    }
                } else if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            app[key] = value
        }

        setApp({ ...app })
    }

    const handleEnableApp = () => {
        const newValue = !app.status ? true : false
        handleValue({ target: { value: newValue } }, 'status')
    }

    const handleEnableQueryString = () => {
        const newValue = !app.query_string_sql ? true : false
        handleValue({ target: { value: newValue } }, 'query_string_sql')
    }

    const handleEnableCache = () => {
        const newValue = !app.cache_query ? true : false
        handleValue({ target: { value: newValue } }, 'cache_query')
    }

    const handleEnableShowErrors = () => {
        const newValue = !app.debug_on_response ? true : false
        handleValue({ target: { value: newValue } }, 'debug_on_response')
    }

    const handleEnableAuth = () => {
        const newValue = !app.has_authentication ? true : false
        handleValue({ target: { value: newValue } }, 'has_authentication')
    }

    useEffect(() => {
        if(Object.keys(editApp)?.length) {
            setApp({
                'name': editApp?.name,
                'endpoint_name': editApp?.endpoint_name,
                'endpoint_name2': editApp?.endpoint_name2,
                'connection_id': editApp?.connection_id,
                'app_description': editApp?.app_description,
                'group_id': editApp?.group_id,
                'acl_id': editApp?.acl_id || null,
                'cache_query': editApp?.cache_query == 1 ? true : false,
                'debug_on_response': editApp?.debug_on_response == 1 ? true : false,
                'has_authentication': editApp?.has_authentication == 1 ? true : false,
                'table': editApp?.table,
                'status': editApp?.status == 1 ? true : false,
                'query_string_sql': editApp?.query_string_sql == 1 ? true : false,
                'cors_ids': editApp?.cors_ids?.length && editApp?.cors_ids || [],
                'response': {
                    'list': {
                        'schema': editApp?.response?.list?.schema || [],
                        'has_total_count': editApp?.response?.list?.has_total_count == 1 ? true : false
                    },
                    'show': {
                        'schema': editApp?.response?.show?.schema || [],
                    },
                    'create': {
                        'schema': editApp?.response?.create?.schema || [],
                    },
                    'update': {
                        'schema': editApp?.response?.update?.schema || [],
                    },
                    'delete': {
                        'schema': editApp?.response?.delete?.schema || [],
                    }
                },
                'request': {
                    'list': {
                        'status': editApp?.request?.list?.status || 0,
                        'order_by': editApp?.request?.list?.order_by,
                        'order_by_type': editApp?.request?.list?.order_by_type,
                        'raw_query': editApp?.request?.list?.raw_query || [],
                        'merge_values': editApp?.request?.list?.merge_values || [],
                        'description': '',
                        'dev_mode': {
                            'dev': {
                                'code': editApp?.request?.list?.dev_mode?.dev?.code || '',
                            },
                            'sdb': {
                                'code': editApp?.request?.list?.dev_mode?.sdb?.code || '',
                            },
                            'hml': {
                                'code': editApp?.request?.list?.dev_mode?.hml?.code || '',
                            },
                            'prd': {
                                'code': editApp?.request?.list?.dev_mode?.prd?.code || '',
                            }
                        }
                    },
                    'show': {
                        'status': editApp?.request?.show?.status || 0,
                        'raw_query': editApp?.request?.show?.raw_query || [],
                        'merge_values': editApp?.request?.list?.merge_values || [],
                        'description': '',
                        'dev_mode': {
                            'dev': {
                                'code': editApp?.request?.show?.dev_mode?.dev?.code || '',
                            },
                            'sdb': {
                                'code': editApp?.request?.show?.dev_mode?.sdb?.code || '',
                            },
                            'hml': {
                                'code': editApp?.request?.show?.dev_mode?.hml?.code || '',
                            },
                            'prd': {
                                'code': editApp?.request?.show?.dev_mode?.prd?.code || '',
                            }
                        }
                    },
                    'create': {
                        'status': editApp?.request?.create?.status || 0,
                        'schema': editApp?.request?.create?.schema,
                        'description': '',
                        'worker_id': editApp?.request?.create?.worker_id || null,
                        'dev_mode': {
                            'dev': {
                                'code': editApp?.request?.create?.dev_mode?.dev?.code || '',
                            },
                            'sdb': {
                                'code': editApp?.request?.create?.dev_mode?.sdb?.code || '',
                            },
                            'hml': {
                                'code': editApp?.request?.create?.dev_mode?.hml?.code || '',
                            },
                            'prd': {
                                'code': editApp?.request?.create?.dev_mode?.prd?.code || '',
                            }
                        }
                    },
                    'update': {
                        'status': editApp?.request?.update?.status || 0,
                        'where': editApp?.request?.update?.where,
                        'schema': editApp?.request?.update?.schema,
                        'description': '',
                        'worker_id': editApp?.request?.update?.worker_id || null,
                        'dev_mode': {
                            'dev': {
                                'code': editApp?.request?.update?.dev_mode?.dev?.code || '',
                            },
                            'sdb': {
                                'code': editApp?.request?.update?.dev_mode?.sdb?.code || '',
                            },
                            'hml': {
                                'code': editApp?.request?.update?.dev_mode?.hml?.code || '',
                            },
                            'prd': {
                                'code': editApp?.request?.update?.dev_mode?.prd?.code || '',
                            }
                        }
                    },
                    'delete': {
                        'status': editApp?.request?.delete?.status || 0,
                        'raw_query': editApp?.request?.delete?.raw_query || '',
                        'description': '',
                        'worker_id': editApp?.request?.delete?.worker_id || null,
                        'dev_mode': {
                            'dev': {
                                'code': editApp?.request?.delete?.dev_mode?.dev?.code || '',
                            },
                            'sdb': {
                                'code': editApp?.request?.delete?.dev_mode?.sdb?.code || '',
                            },
                            'hml': {
                                'code': editApp?.request?.delete?.dev_mode?.hml?.code || '',
                            },
                            'prd': {
                                'code': editApp?.request?.delete?.dev_mode?.prd?.code || '',
                            }
                        }
                    }
                }
            })
        } else {
            console.log(corsData)
            setApp({
                'name': '',
                'endpoint_name': '',
                'endpoint_name2': '',
                'app_description': '',
                'connection_id': null,
                'group_id': null,
                'acl_id': null,
                'cache_query': false,
                'has_authentication': false,
                'debug_on_response': false,
                'table': '',
                'status': false,
                'query_string_sql': false,
                'cors_ids': [],
                'response': {
                    'list': {
                        'schema': [],
                        'has_total_count': false
                    },
                    'show': {
                        'schema': [],
                    },
                    'create': {
                        'schema': [],
                    },
                    'update': {
                        'schema': [],
                    },
                    'delete': {
                        'schema': [],
                    }
                },
                'request': {
                    'list': {
                        'status': 0,
                        'order_by': null,
                        'order_by_type': null,
                        'raw_query': [],
                        'merge_values': [],
                        'description': '',
                        'dev_mode': {
                            'dev': {
                                'code': '',
                            },
                            'sdb': {
                                'code': '',
                            },
                            'hml': {
                                'code': '',
                            },
                            'prd': {
                                'code': '',
                            }
                        }
                    },
                    'show': {
                        'status': 0,
                        'raw_query': [],
                        'merge_values': [],
                        'description': '',
                        'dev_mode': {
                            'dev': {
                                'code': '',
                            },
                            'sdb': {
                                'code': '',
                            },
                            'hml': {
                                'code': '',
                            },
                            'prd': {
                                'code': '',
                            }
                        }
                    },
                    'create': {
                        'status': 0,
                        'schema': '',
                        'description': '',
                        'worker_id': '',
                        'dev_mode': {
                            'dev': {
                                'code': '',
                            },
                            'sdb': {
                                'code': '',
                            },
                            'hml': {
                                'code': '',
                            },
                            'prd': {
                                'code': '',
                            }
                        }
                    },
                    'update': {
                        'status': 0,
                        'where': '',
                        'schema': '',
                        'description': '',
                        'worker_id': '',
                        'dev_mode': {
                            'dev': {
                                'code': '',
                            },
                            'sdb': {
                                'code': '',
                            },
                            'hml': {
                                'code': '',
                            },
                            'prd': {
                                'code': '',
                            }
                        }
                    },
                    'delete': {
                        'status': 0,
                        'raw_query': '',
                        'description': '',
                        'worker_id': '',
                        'dev_mode': {
                            'dev': {
                                'code': '',
                            },
                            'sdb': {
                                'code': '',
                            },
                            'hml': {
                                'code': '',
                            },
                            'prd': {
                                'code': '',
                            }
                        }
                    }
                }
            })
        }
        console.log(app)
        setModalIsLoading(false)
    }, [editApp])

    useEffect(() => {
        if(!modalIsLoading && Object.keys(app)?.length) {
            setTabs([
                {
                    name: <Tag color="success">LIST | GET</Tag>,
                    content: <TabList values={{
                            response: app?.response?.list,
                            request: app?.request?.list
                        }} 
                        handleValue={handleValue} 
                        appId={editApp?._id}
                        isEdit={isEditModal}
                        endpointName={app?.endpoint_name}
                        endpointName2={app?.endpoint_name2}
                    />
                },
                {
                    name: <Tag color="success">GET</Tag>,
                    content: <TabGet values={{
                            response: app?.response?.show,
                            request: app?.request?.show
                        }} 
                        handleValue={handleValue} 
                        appId={editApp?._id}
                        isEdit={isEditModal}
                        endpointName={app?.endpoint_name}
                        endpointName2={app?.endpoint_name2}
                    />
                },
                {
                    name: <Tag color="processing">POST</Tag>,
                    content: <TabPost values={{
                            request: app?.request?.create
                        }} 
                        handleValue={handleValue} 
                        appId={editApp?._id}
                        isEdit={isEditModal}
                        endpointName={app?.endpoint_name}
                        endpointName2={app?.endpoint_name2}
                        workers={workersData}
                    />
                },
                {
                    name: <Tag color="processing">PUT</Tag>,
                    content: <TabPut values={{
                            request: app?.request?.update
                        }} 
                        handleValue={handleValue} 
                        appId={editApp?._id}
                        isEdit={isEditModal}
                        endpointName={app?.endpoint_name}
                        endpointName2={app?.endpoint_name2}
                        workers={workersData}
                    />
                },
                {
                    name: <Tag color="error">DELETE</Tag>,
                    content: <TabDelete values={{
                            response: app?.response?.delete,
                            request: app?.request?.delete
                        }} 
                        handleValue={handleValue} 
                        appId={editApp?._id}
                        isEdit={isEditModal}
                        endpointName={app?.endpoint_name}
                        endpointName2={app?.endpoint_name2}
                        workers={workersData}
                    />
                }
            ])
        }
    }, [app, editApp])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={async () => { 
                handleOpen(false) 
            }}
            width={1000}
            onOk={async () => handleSave()}
            okText="Salvar"
            cancelText="Cancelar"
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ loading: loading }}
        >
            {!modalIsLoading && (
                <Row 
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={24}>

                        <Form 
                            layout={'vertical'}
                            initialValues={{
                                ...app
                            }}
                        >
                            {isEditModal && (<Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={9}>
                                    <Item label="ID">
                                        <Input disabled value={editApp?._id}  />
                                    </Item>
                                </Col>
                            </Row>)}
                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={9}>
                                    <Item label="Nome">
                                        <Input placeholder="Nome do app" onChange={(e) => handleValue(e, 'name')} value={app?.name}  />
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={9}>
                                    <Item label="Tabela">
                                        <Input placeholder="Nome da tabela" onChange={(e) => handleValue(e, 'table')} value={app?.table}  />
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <Item label="Grupo">
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            options={[{ label: 'Nenhum Grupo', value: null }].concat(groupsData?.data?.map(group => {
                                                return {
                                                    label: group.name,
                                                    value: group._id
                                                }
                                            })) || []} 
                                            onChange={(e) => handleValue({ target: { value: e }}, 'group_id')}
                                            onInputKeyDown={(e) => e.preventDefault()}
                                            value={app?.group_id}
                                        />
                                    </Item>
                                </Col>
                            </Row>

                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={18}>
                                    <Item label="Descrição">
                                        <Input.TextArea placeholder="Descrição da API" onChange={(e) => handleValue(e, 'app_description')} value={app?.app_description}  />
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <Item label="CORS">
                                        <Tooltip placement="topLeft" title="O CORS protege suas APIs verificando se o header Origin é permitido ou não">
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Selecionar CORS"
                                                options={corsData?.data?.length > 0 && corsData?.data?.map(cors => {
                                                    return {
                                                        label: cors.name,
                                                        value: cors._id
                                                    }
                                                }) || []} 
                                                onChange={(e) => handleValue({ target: { value: e }}, 'cors_ids', 'array')}
                                                onInputKeyDown={(e) => e.preventDefault()}
                                                value={app?.cors_ids}
                                            />
                                        </Tooltip>
                                    </Item>
                                </Col>
                            </Row>

                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={6}>
                                    <Item label="Conexão">
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Selecionar conexão"
                                            options={connectionsData?.data?.length > 0 && connectionsData?.data?.map(conn => {
                                                return {
                                                    label: conn.name,
                                                    value: conn._id
                                                }
                                            }) || []} 
                                            onChange={(e) => handleValue({ target: { value: e }}, 'connection_id')}
                                            onInputKeyDown={(e) => e.preventDefault()}
                                            value={app?.connection_id}
                                        />
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <Item label="ACL">
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Selecione um ACL..."
                                            options={[{ label: 'Nenhum ACL', value: null }].concat(aclData?.data?.map(acl => {
                                                return {
                                                    label: acl.name,
                                                    value: acl._id
                                                }
                                            })) || []} 
                                            onChange={(e) => handleValue({ target: { value: e }}, 'acl_id')}
                                            onInputKeyDown={(e) => e.preventDefault()}
                                            value={app?.acl_id}
                                        />
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <Item label="Nome endpoint">
                                        <Input placeholder="Nome do enpoint" onChange={(e) => handleValue(e, 'endpoint_name')} value={app?.endpoint_name}  />
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <Item label="Nome endpoint 2">
                                        <Input placeholder="Nome do enpoint2" onChange={(e) => handleValue(e, 'endpoint_name2')} value={app?.endpoint_name2}  />
                                    </Item>
                                </Col>
                            </Row>

                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={6}>
                                    <Item label="Cachear queries">
                                        <Tooltip placement="topLeft" title="Salvar dados de resposta no Redis (diminui o tempo de resposta da api)">
                                            <Switch 
                                                onChange={handleEnableCache}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                checked={app?.cache_query}
                                            />
                                        </Tooltip>
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <Item label="Autenticação">
                                        <Tooltip placement="topLeft" title="Usar autenticação JWT e OAUTH2 (requer reverse authentication)">
                                            <Switch 
                                                onChange={handleEnableAuth}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                checked={app?.has_authentication}
                                            />
                                        </Tooltip>
                                    </Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    {
                                        isEditModal && (
                                            <Item label="Status">
                                                <Tooltip placement="topLeft" title="App ativo/desativado">
                                                    <Switch 
                                                        onChange={handleEnableApp}
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                        checked={app?.status == 1 ? true : false}
                                                    />
                                                </Tooltip>
                                            </Item>
                                        )
                                    }
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <Item label="Query String">
                                        <Tooltip placement="topLeft" title="Permitir injetar query string no SQL (List/Get)">
                                            <Switch 
                                                onChange={handleEnableQueryString}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                checked={app?.query_string_sql}
                                            />
                                        </Tooltip>
                                    </Item>
                                </Col>
                            </Row>

                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={6}>
                                    
                                    <Item label="Mostrar erros">
                                        <Tooltip placement="topLeft" title="Mostrar erros na resposta da API">
                                            <Switch 
                                                onChange={handleEnableShowErrors}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                checked={app?.debug_on_response}
                                            />
                                        </Tooltip>
                                    </Item>
                                </Col>
                            </Row>

                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col span={24}>
                                    <Tabs
                                        tabPosition={isMobile ? 'top' : 'left'}
                                        items={tabs.map((tab, i) => {
                                            const id = String(i);
                                            return {
                                                label: tab.name,
                                                key: id,
                                                children: tab.content
                                            }
                                        })}
                                    />
                                </Col>
                            </Row>
            
                        </Form>

                    </Col>
                </Row>
            )}
        </Modal>
    )
} 